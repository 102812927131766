import React from 'react';

const ExploreSection = () => {
  return (
    <div className="explore-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="explore-content">
              <h2>Explore the Future of Business Efficiency</h2>
              <p>with Inveca, your dedicated partner in success.</p>
              {/* <a href="/signup" className="learn-more-button">Get Started</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreSection;
