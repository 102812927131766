import React from "react";
import { AiOutlineDatabase, AiOutlineSchedule, AiOutlineLineChart, AiOutlineRobot } from 'react-icons/ai';

const FeatureCard = ({icon, title, content}) => {
  return (
      <div className="content-card d-flex flex-column active">
        <div className="card-icon">
          {icon}
        </div>
        <div className="card-title">
          <h2>{title}</h2>
        </div>
        <div className="card-content">
          <p>{content}</p>
        </div>
      </div>
  )
}

const featureItems = [
    {
        icon: <AiOutlineDatabase />,
        title: "Comprehensive Data Management",
        content:
            "Capture and centralize all field-related data, including location details, purpose of visits, customer information, discussion summaries, stock levels, and comments.",
    },
    {
        icon: <AiOutlineSchedule />,
        title: "Efficient Work Planning:",
        content:
            "Replace traditional planning tools with a modern work planner that facilitates daily, weekly, monthly, and yearly plans for field staff, ensuring optimized scheduling",
    },
    {
        icon: <AiOutlineLineChart />,
        title: "Real-Time Reporting",
        content:
            "Generate real-time reports and gain visibility into daily activities, enabling quick decision-making and improving follow-up processes.Generate real-time reports and gain visibility into daily activities, enabling quick decision-making and improving follow-up processes.",
    },
];

const OurFeatures = () => {
    return (
        <div id="features" className="our-features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-12 align-self-center wow fadeInRight animated">
                                <div className="about-content">
                                    <h3>Our Features</h3>
                                    <h2>Providing Solutions to Streamlined Operations</h2>
                                    <p>
                                        Unlock business potential with Inveca: CRM essentials plus AI insights.
                                        Elevate performance effortlessly. It's not just what you want, it's what you
                                        need.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-around flex-wrap">
                            {featureItems.map((item, index) => (
                                <div className="col-lg-3 ml-3 mr-3" key={index}>
                                    <FeatureCard icon={item.icon} title={item.title} content={item.content} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurFeatures;
