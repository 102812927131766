import React from "react";
import aboutImage from "../../assets/images/top_management_transparent.png";

const AboutUs = () => {
    return (
        <div id="about" className="about-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-left-image wow fadeInLeft animated">
                                    <img className="banner-image" src={aboutImage} alt="About Us" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center wow fadeInRight animated">
                                <div className="about-right-content">
                                    <div className="section-heading">
                                        <h3>ABOUT US</h3>
                                        <h2>
                                            What is <em>Inveca</em>
                                        </h2>
                                    </div>
                                    <p>
                                        Inveca revolutionizes business operations by addressing challenges in inventory tracking, client management, and customer retention.
                                        Our platform provides actionable insights, efficient inventory management, and personalized feedback, ensuring you can focus on growing your business and delighting your customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
