import React from 'react';
import mainBannerImage from '../../assets/images/bannerImage.png';
import "../../styles/styles.css";

const MainBanner = () => {
    return (
        <div className="main-banner wow fadeIn animated" id="top" data-wow-duration="1s" data-wow-delay="0.5s" >
            <div className="container h-100">
                <div className="row h-100 d-flex align-items-center">
                    <div className="col-lg-12 pt-3">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="left-content show-up header-text wow fadeInLeft animated" data-wow-duration="1s" data-wow-delay="1s">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h6>WELCOME TO INVECA</h6>
                                        <h2>Optimizing Business Efficiency and Customer Retention</h2>
                                        <p>
                                            Inveca empowers businesses to streamline operations, track inventory effortlessly, and gain personalized insights to retain more customers.
                                            Our platform is designed to help you achieve higher efficiency, better inventory management, and stronger customer relationships.
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="rightImage wow fadeInRight animated" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img className="banner-image" src={mainBannerImage} alt="Marketing" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainBanner;
