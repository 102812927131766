import React, { useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./styles/styles.css";
import "./styles/hamburgers.css";
import "./styles/custom_styles.css";

import { GuestLayout } from "./components/layouts";
import { ContextProvider } from "./context/ContextProvider";
import { AsideProvider } from "./context/asideContext";
import { defaultPaths } from "./constants/routes";
import PageNotFound from "./pages/404";

function App() {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const modeClass = isDarkMode ? "dark-mode" : "";

    const router = createBrowserRouter([
        {
            path: "/",
            element: <GuestLayout />,
            children: defaultPaths,
        },
        {
            path: "*",
            element: <PageNotFound />,
        }
    ]);

    return (
        <div className={`App ${modeClass}`}>
            <AsideProvider>
                <ContextProvider>
                    <RouterProvider router={router} />
                </ContextProvider>
            </AsideProvider>
        </div>
    );
}

export default App;
