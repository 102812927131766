import NavBar from "../../components/default/navBar";
import Footer from "../../components/default/footer"
import MainBanner from "../../components/default/mainBanner";
import AboutUs from "../../components/default/bodySection";
import OurFeatures from "../../components/default/ourFeatures";
import ExploreSection from "../../components/default/exploreSection";
import ContactSection from "../../components/default/contactSection";

function Home() {
  return (
    <>
      <NavBar />
      <MainBanner />
      <AboutUs />
      <OurFeatures />
      <ExploreSection />
      <ContactSection />
      <Footer />
    </>
  );
}

export default Home;
