import Home from "../pages/default/index";

const routes = {
	Home: "/",
};


const defaultPaths = [
	{
		path: routes.Home,
		element: <Home />,
	}
];

export { routes, defaultPaths };
