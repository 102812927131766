import react from "react";

const PageNotFound = () => {
	return (
		<div>
			<div className="notfound">
				<div className="notfound-404">
					<h1>
						4<span></span>4
					</h1>
				</div>
				<h2>Oops! Page Not Be Found</h2>
				<p>
					Sorry but the page you are looking for does not exist, have
					been removed. name changed or is temporarily unavailable
				</p>
				<a href="/">Back to homepage</a>
			</div>
            <script async="" src="https://www.googletagmanager.com/gtag/js?id=UA-23581568-13"></script>
		</div>
	);
};

export default PageNotFound;