import { createContext, useContext, useState } from 'react';

const AsideContext = createContext();

export const AsideProvider = ({ children }) => {
  const [isClosed, setIsClosed] = useState(true);

  const toggleMenu = () => {
    setIsClosed((prevIsClosed) => !prevIsClosed);
  };

  return (
    <AsideContext.Provider value={{ isClosed, toggleMenu }}>
      {children}
    </AsideContext.Provider>
  );
};

export const useAside = () => {
  const context = useContext(AsideContext);
  if (!context) {
    throw new Error('useAside must be used within an AsideProvider');
  }
  return context;
};
