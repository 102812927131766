import React from "react";
import { DefaultContext } from "../context/context";
import { Outlet } from "react-router-dom";

const GuestLayout = () => {
    return (
        <div>
            <DefaultContext />
            <Outlet />
        </div>
    );
};

export { GuestLayout };
